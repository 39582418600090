// Parse old-style URLs and update the address.

// Old URL path pattern
var _oldPathPattern = new RegExp(
  '((?:/catalogs/[^/#]+)?' + // Catalog name
  '(?:/repositories/[^/#]+)?' + // repo name
  '(?:/session/\\d+/sessions/[^/#]*)?)' + // session port + UUID
  '/?(\\?[^#]*)?(?:#([\\s\\S]*))?$'); // Query string + fragment.

function redirect() {
  var url = window.location.href.replace(
    _oldPathPattern,
    function (_match, path, query, hash) {
      if (path && path.charAt(path.length - 1) !== '/') {
        path += '/';
      }
      return (query || '') + '#' + path + (hash || '');
    });
  window.location.replace(url);
}

redirect();
